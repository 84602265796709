<template>
    <div>
        <h4>Main Page</h4>
        <b-container>
            <b-row>
                <b-col md="6">
                    <b-card v-for="image in mainImageList" v-bind:key="image.id">
                        <b-container>
                            <b-row>
                                <b-col cols="12">
                                    <a :href="image.url" target="_blank"><img :src="image.url" class="img-fluid thumbnail" :alt="image.title"></a>
                                </b-col>
                                <b-col cols="12" class="mt-1">
                                    <a href="#" v-if="mainImageList.length > 1" class="delete" @click.prevent="deleteMain(image.id)">Delete</a>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card>
                        <b>Upload new image</b>
                        <b-form-group id="file-group">
                            <b-form-file v-model="file" type="file" accept="image/png, image/jpeg, image/gif" id="file" ref="file" required/>
                        </b-form-group>

                        <b-button class="default-button" v-on:click="submitFileMain()">Submit</b-button><img v-show="uploading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from "axios";
    import config from 'config';

    export default {
        name: "Main",
        data () {
            return {
                mainImageList: [],
                file: '',
                uploading: false,
            }
        },
        mounted() {
            this.getMainImages();
        },
        methods: {
            getMainImages() {
                this.mainImageList = [];
                axios.get(`${config.apiUrl}/carousel/main`)
                    .then(response => {
                        response.data.forEach(e => {
                            this.mainImageList.push({
                                'id': e.id,
                                'url': `${config.apiUrl}${e.img_path}`,
                                'title': e.img_title,
                                'description': e.img_caption
                            });
                        })
                    });
            },
            deleteMain(imageId) {
                if (confirm('Are you sure you want to delete this image?')) {
                    axios.delete(`${config.apiUrl}/carousel/main`, {
                        data: `id=${imageId}`,
                        withCredentials: false,
                        headers: {
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`,
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    },).then(() => this.getMainImages())
                        .catch((e) => {
                            if (e.response) {
                                console.log(e.response.status)
                                if (e.response.status === 403) {
                                    this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                                    this.$router.push('/login');
                                } else if (e.response.status === 502) {
                                    this.$store.dispatch('alert/error', 'Server error.', {root: true});
                                } else {
                                    this.$store.dispatch('alert/error', e.response, { root: true });
                                }
                            }
                        });
                }
            },
            submitFileMain(){
                if (this.file === '') {
                    alert("Please select a picture.");
                    return;
                }
                this.uploading = true;

                let formData = new FormData();
                formData.append('img_file', this.file);
                formData.append('img_title', ' ');
                formData.append('img_caption', ' ');

                axios.post( `${config.apiUrl}/carousel/main`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                        }
                    }
                ).then(() => {
                    this.getMainImages();
                    this.clearFileInput();
                    this.uploading = false;
                }).catch((e) => {
                    this.uploading = false;
                    if (e.response) {
                        console.log(e.response.status)
                        if (e.response.status === 403) {
                            this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                            this.$router.push('/login');
                        } else if (e.response.status === 502) {
                            this.$store.dispatch('alert/error', 'Server error.', {root: true});
                        } else if (e.response.data.message) {
                            this.$store.dispatch('alert/error', e.response.data.message, { root: true });
                        } else {
                            this.$store.dispatch('alert/error', e.response.data, { root: true });
                        }
                    }
                });
            },
            clearFileInput () {
                const input = this.$refs.file;
                input.type = 'text';
                input.type = 'file';
            },
        }
    }
</script>

<style scoped>
    .thumbnail {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .delete {
        color: red;
    }
</style>
